import { Pipe, PipeTransform } from '@angular/core';
import { IInput } from '@bs/models/common/forms';
import { TableAction } from '@bs/models/common/tables';
import { IMe } from '@bs/models/me/me';

import { AuthService } from '@bs/services/services/core/auth.service';

/**
 * this pipe checks if logged user has a permissions to see some buttons or templates on the application
 */
@Pipe({
  name: 'hasPermission',
  standalone: true
})
export class HasPermissionPipe implements PipeTransform {

  /**
   * user information variable
   */
  me: IMe;

  /**
   * The constructor sets the user information
   */
  constructor(auth: AuthService) {
    this.me = auth.accountLogged$.getValue();
    //console.log(this.me.permissions?.booleans)
  }

  /**
   * returns us the matched user permissions
   * @param items {Array | number} the permissions of user which can be passed as array or just a single value
   */
  transform(items: Array<TableAction | IInput> | number): any {
    if (Array.isArray(items)) {
      return items.filter(i => i.permission ? this.me.permissions?.booleans?.includes(i.permission) : true);
    } else {
      return this.me.permissions?.booleans?.includes(items);
    }

  }
}
